import { Auth, Logger } from "aws-amplify";

const logger = new Logger("Utils");

export const redirect = () => {
  Auth.currentAuthenticatedUser()
    .then((user) => {
      const searchParams = new URLSearchParams(window.location.search);
      const redirect = searchParams.get("redirect_uri");

      if (user && redirect) {
        logger.info(`redirect to ${redirect}`);
        // @ts-ignore
        window.location = redirect;
      }
    })
    .catch((err) => console.error(err));
};
