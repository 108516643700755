import React, { useEffect } from "react";
import { Hub } from "aws-amplify";
import {
  SignIn,
  ForgotPassword,
  RequireNewPassword,
  Greetings,
  withAuthenticator,
} from "aws-amplify-react";

import { redirect } from "./utils";

function App() {
  useEffect(redirect, []);

  Hub.listen("auth", (data) => {
    const { event } = data.payload;

    if (event === "signIn") {
      redirect();
    }
  });

  return <Greetings />;
}

export default withAuthenticator(App, false, [
  <SignIn />,
  <ForgotPassword />,
  <RequireNewPassword />,
]);
