import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import Amplify from "aws-amplify";
import ReactDOM from "react-dom";
import App from "./App";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_WEB_CLIENT_ID,
    mandatorySignIn: true,
    cookieStorage: {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      path: "/",
      expires: 7, // Cookie expiration in days
      secure: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
